import sgImgS1 from '../images/service-single/graphics/img-1.jpg'
import sgImgS2 from '../images/service-single/graphics/img-2.jpg'
import sgImgS3 from '../images/service-single/graphics/img-3.jpg'

import brImgS1 from '../images/service-single/brand/img-1.jpg'
import brImgS2 from '../images/service-single/brand/img-2.jpg'
import brImgS3 from '../images/service-single/brand/img-3.jpg'

import uxImgS1 from '../images/service-single/ux/img-1.jpg'
import uxImgS2 from '../images/service-single/ux/img-2.jpg'
import uxImgS3 from '../images/service-single/ux/img-3.jpg'

import dvImgS1 from '../images/service-single/development/img-1.jpg'
import dvImgS2 from '../images/service-single/development/img-2.jpg'
import dvImgS3 from '../images/service-single/development/img-3.jpg'

import adImgS1 from '../images/service-single/app/img-1.jpg'
import adImgS2 from '../images/service-single/app/img-2.jpg'
import adImgS3 from '../images/service-single/app/img-3.jpg'

import swImgS1 from '../images/service-single/softwere/img-1.jpg'
import swImgS2 from '../images/service-single/softwere/img-2.jpg'
import swImgS3 from '../images/service-single/softwere/img-3.jpg'

import smImgS1 from '../images/service-single/sm/img-1.jpg'
import smImgS2 from '../images/service-single/sm/img-2.jpg'
import smImgS3 from '../images/service-single/sm/img-3.jpg'

import dmImgS1 from '../images/service-single/dm/img-1.jpg'
import dmImgS2 from '../images/service-single/dm/img-2.jpg'
import dmImgS3 from '../images/service-single/dm/img-3.jpg'

import emailImgS1 from '../images/service-single/email/img-1.jpg'
import emailImgS2 from '../images/service-single/email/img-2.jpg'
import emailImgS3 from '../images/service-single/email/img-3.jpg'



const Services = [
    {
        Id: '1',
        sImgS:uxImgS1,
        sTitle: 'UI/UX Design',
        description:'I create user-friendly interfaces that prioritize both aesthetics and functionality, ensuring a seamless user experience. Technologies I can use include Figma.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-add',
        ssImg1:uxImgS2,
        ssImg2:uxImgS3,
    },
    {
        Id: '2',
        sImgS:adImgS1,
        sTitle: 'IOS Development',
        description:'I build dynamic iOS apps that are efficient, reliable, and tailored to meet modern standards. Technologies I can use include Xcode, Swift, SwiftUI.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-smartphone',
        ssImg1:adImgS2,
        ssImg2:adImgS3,
    },
    {
        Id: '3',
        sImgS:swImgS1,
        sTitle: 'Backend Development',
        description:'I design scalable backend systems that ensure seamless integration and performance. Technologies I can use include FastAPI, Node.js, or MySQL.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-app-development',
        ssImg1:swImgS2,
        ssImg2:swImgS3,
    },
]    

export default Services;