import React from 'react'

const SectionTitle = (props) => {
    return (
        <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
                <div className="wpo-section-title">
                    <h2>How Can I Help?</h2>
                    <p>I specialize in IOS development and designing apps that are intuitive, user-friendly, and high-performing. I can help bring your mobile app ideas to life with a focus on both functionality and design.</p>
                </div>
            </div>
        </div>
    )
}

export default SectionTitle;